.top-logo {
    width: 72px;
    height: 72px;
}

.user-icon-dropdown {
    position: absolute;
    right: 0;
    top: 50px;
    background: #f5f5f5;
}

.user-icon-dropdown>.self-stretch:hover {
    cursor: default;
}