.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn:hover {
  cursor: pointer;
  font-weight: 700;
}

.btn-disabled:hover {
  cursor: default;
}

#spinner svg {
  fill: #4338ca !important;
}

/* Default scrollbar width */
::-webkit-scrollbar {
  width: 14px;
}

/* Default scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #5145CD; 
  border-radius: 8px; 
  width: 10px; 
  height: 50px; /* Set height */
  border: 2px solid #f2f2f2; /* Add border */
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);  */
  cursor: pointer;
}

/* Default scrollbar track */
::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 8px; 
  width: 14px;
}

/* On hover, increase scrollbar width */
::-webkit-scrollbar:hover {
  width: 16px; /* Increase width on hover */
}