.drawer {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: width 0.3s ease-in-out;
  z-index: 10;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.drawer.open {
  width: 30vw;
  /* Default width for medium screens */
}

@media (max-width: 1024px) {
  .drawer.open {
    width: 50vw;
    /* Smaller width for larger screens */
  }
}

/* For smaller screens */
@media (max-width: 768px) {
  .drawer.open {
    width: 70vw;
    /* Larger width for smaller screens */
  }
}