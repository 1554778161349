.top-logo-login {
  width: 12.5%;
  height: 12.5%;
  position: absolute;
  left: 42.5%;
  top: 12.5%;
  line-height: 21px;
}

::-webkit-input-placeholder {
  text-align: center;
  color: gray;
  font-weight: 500;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
  color: gray;
  font-weight: 500;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
  color: gray;
  font-weight: 500;
}

:-ms-input-placeholder {
  text-align: center;
  color: gray;
  font-weight: 500;
}

.radio:hover {
  cursor: pointer;
}
