.dashbord-layout {
  display: flex;
  width: 1336px;
  height: 768px;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.dashboard-card {
  width: 876px;
  height: 444px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--indigo-700, #5145cd);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.04),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  width: 240px;
  color: var(--white, #fff);
  /* text-5xl/font-medium */
  /* font-family: Inter; */
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  /* 72px */
}

.dashboard-sub-header {
  width: 455px;
  color: var(--white, #fff);
  /* text-2xl/font-normal */
  /* font-family: Inter; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  padding: 2.5% 0;
  /* 36px */
}

.dashboard-text {
  width: 401px;
  color: var(--white, #fff);
  /* text-base/font-light */
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 2.5% 0;
  /* 24px */
}

.dashboard-link {
  width: 279px;
  color: var(--white, #fff);
  /* text-lg/font-medium */
  /* font-family: Inter; */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding: 2.5% 0;
  /* 27px */
}

.dashboard-install-platform {
  width: 88px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
}

.mac-icon,
.windows-icon {
  margin: 20%;
}

.mac-icon {
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  background: url("./images/mac.png"), lightgray 50% / cover no-repeat;
}

.windows-icon {
  width: 40px;
  height: 40px;
  background: url("./images/windows.png"), lightgray 50% / cover no-repeat;
}

.download-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-top: 50%;
}

.chat-1 {
  width: 314px;
  height: 171px;
  flex-shrink: 0;
  position: absolute;
  left: 52.2%;
  top: 60%;
  z-index: 10000;
  background: transparent;
  border-radius: 10px 0px 0px 10px;
  background: url("./images/PeopleChats1.png"),
    lightgray -0.187px -82px / 207.652% 195.652% no-repeat;
}

.chat-2 {
  width: 250px;
  height: 189px;
  position: absolute;
  left: 56.25%;
  top: 40%;
  border-radius: 10px 0px 0px 10px;
  background: url("./images/PeopleChats2.png"),
    lightgray -0.187px 0px / 138.867% 166.667% no-repeat;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.display-black-bg {
  width: 279px;
  height: 242px;
  position: absolute;
  left: 54.5%;
  top: 50%;
  flex-shrink: 0;
  border-radius: 10px 0px 0px 10px;
  background: url("./images/DisplayBlack.png"),
    lightgray 0px -0.125px / 179.211% 116.219% no-repeat;
}

.left-section {
  padding: 7.5%;
}

.sign-button-section {
  padding: 2.5% 0;
}

.sign-button-section button {
  color: var(--white, #fff);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}